import React from "react";
import Spacer from "../Spacer/Spacer";
import "./VRPrices.scss";

export default function VRPrices() {
  return (
    <div className="VRPrices">
      <div className="priceBox">
        <div className="title">360°</div>
        <Spacer h={24}></Spacer>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Konzept
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Storyboard
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Projektsteuerung
        </div>
        <Spacer h={24}></Spacer>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Standalone VR
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Video Aufnahme
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Video Schnitt
        </div>

        <Spacer h={24}></Spacer>
        <div className="flex1"></div>
        <div className="price">ab 6.000€</div>
        <Spacer h={24}></Spacer>
         <a href="#contact" className="button">Jetzt anfragen</a>
      </div>
      <div className="priceBox">
        <div className="title">VR Lite</div>
        <Spacer h={24}></Spacer>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Konzept
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Storyboard
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Projektsteuerung
        </div>
        <Spacer h={24}></Spacer>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Standalone VR
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Interaktive Szene
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          3D Umgebung
        </div>
        <Spacer h={24}></Spacer>
        <div className="flex1"></div>
        <div className="price">ab 8.000€</div>
        <Spacer h={24}></Spacer>
         <a href="#contact" className="button">Jetzt anfragen</a>
      </div>
      <div className="priceBox">
        <div className="title">VR Custom</div>
        <Spacer h={24}></Spacer>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Konzept
        </div>

        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Storyboard
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Projektsteuerung
        </div>
        <Spacer h={24}></Spacer>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Unbegrenzt interaktive Szenen
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Detailreiche 3D Umgebungen
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          PC-VR Terminal inkl. Bildschirm
        </div>
        <Spacer h={24}></Spacer>
        <div className="flex1"></div>
        <div className="price">ab 16.000€</div>
        <Spacer h={24}></Spacer>
        <a href="#contact" className="button">Jetzt anfragen</a>
      </div>
    </div>
  );
}
