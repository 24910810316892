import React, { useRef } from "react";
import "./Advantages.scss";

export default function Advantages() {
  const advantages = [
    {
      title: "Innovativ",
      text: "Unsere VR-Erlebnisse begeistern durch einzigartige Möglichkeiten, die Welt zu erkunden. Mit neuesten Technologien und kreativen Ideen schaffen wir Erlebnisse, die Besucher inspirieren und faszinieren.",
    },
    {
      title: "Maßgeschneidert",
      text: "Wir bieten VR-Erlebnisse, die auf Ihre Bedürfnisse und Wünsche zugeschnitten sind. In enger Zusammenarbeit mit Ihnen stellen wir sicher, dass das Erlebnis perfekt auf Ihre Anforderungen abgestimmt ist.",
    },
    {
      title: "Intuitiv",
      text: "Mit unserer VR-Technologie wird das Eintauchen in virtuelle Welten kinderleicht. Wir legen großen Wert auf eine intuitive Benutzeroberfläche und ermöglichen so ein unkompliziertes VR-Erlebnis.",
    },
    {
      title: "Mehrsprachig",
      text: "Damit Besucher aus verschiedenen Ländern und Kulturen das VR-Erlebnis genießen können, bieten wir auf Anfrage mehrsprachige Optionen an. Wir sorgen dafür, dass die Übersetzungen von hoher Qualität sind.",
    },
    {
      title: "Interaktiv",
      text: "Mit unseren VR-Erlebnissen wird der Besucher zum Teil der virtuellen Welt. Durch geschicktes Levelbuilding und unserer Erfahrung im User Experience Design in VR sorgen wir für eine tiefe Immersion des Besuchers.",
    },
    {
      title: "Barrierefrei",
      text: "Unsere VR-Erlebnisse sind auf anfrage für alle Besucher zugänglich und barrierefrei gestaltet. Mit speziellen Funktionen wie Audio-Beschreibungen oder Untertiteln stellen wir sicher, dass jeder das VR-Erlebnis genießen kann.",
    },
  ];
  const advantageCount = advantages.length;

  const [selectedAdvantage, setSelectedAdvantage] = React.useState(0);
  //using useEffect, periodically increase the advantage variable
  const intervalTime = useRef(5000);
  React.useEffect(() => {
    let interval = setInterval(() => {
      setSelectedAdvantage((selectedAdvantage + 1) % advantageCount);
    }, intervalTime.current);
    intervalTime.current = 5000;
    return () => clearInterval(interval);
  }, [setSelectedAdvantage, advantageCount, selectedAdvantage]);
  return (
    <div className="Advantages section">
      <div className="inner">
        <div className="title">
          Wir erstellen <span className="bold">Ihre</span> individuelle<br></br>
          <span className="orange">Virtual Reality</span> Experience:
        </div>
        <div className="line"></div>
        <div className="mobAdvantages">
          {advantages.map((advantage, index) => {
            return (
              <div className="advantage">
                <div className="title">{advantage.title}</div>
                <div className="text">{advantage.text}</div>
              </div>
            );
          })}

          <div className="advantage">
            <div className="title">innovativ</div>
            <div className="text">
              lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat sed diam voluptua. Lorem ipsum dolor sit amet, consetetur
              sadipscing elitr, sed diam
            </div>
          </div>
        </div>
        <div className="desktopAdvantages">
          <div className="list">
            {
              // map the advantages to divs
              advantages.map((advantage, index) => {
                return (
                  <div
                    onMouseOver={() => {
                      intervalTime.current = 10000;
                      setSelectedAdvantage(index);
                    }}
                    onClick={() => {
                      intervalTime.current = 10000;
                      setSelectedAdvantage(index);
                    }}
                    className={
                      "advantage " +
                      (selectedAdvantage % advantageCount === index
                        ? "active"
                        : "")
                    }
                  >
                    {advantage.title}
                  </div>
                );
              })
            }
          </div>
          <div className="text">
            "{advantages[selectedAdvantage % advantageCount].text}"
          </div>
        </div>
      </div>
    </div>
  );
}
