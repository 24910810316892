import React from "react";
import SubScreen from "../../atoms/SubScreen/SubScreen";
import "./Zoo1919.scss";

export default function Zoo1919({ onClose }) {
  return (
    <SubScreen
      onClose={onClose}
      title={"Zeitblicke\nVirtual Zoo 1919"}
      left={"Kunde: LBS Bausparkasse\nProjektjahr: 2019"}
      images={[
        "/project/projekt_zoo_00.jpg",
        "/project/projekt_zoo_01.jpg",
        "/project/projekt_zoo_02.jpg",
        "/project/projekt_zoo_03.jpg",
        "/project/projekt_zoo_04.jpg",
      ]}
      mid={`„Lost Places“ zum Leben erwecken – das ist die Faszination am Projekt „Virtual Zoo 1919“. Direkt am Aasee im Herzen von Münster liegt einer der spannendsten versunkenen Orte der Stadt – der alte Zoo. Ein besonders spannendes Kapitel in der Zoo-Historie sind die Jahre zwischen den Weltkriegen.\n\nDie Architektur damals spiegelte den Kolonialismus wieder und imitierte in einem wilden Mix die Stile fremder Kulturen. Unumstrittenes Highlight ist das Elefantenhaus, das einer Moschee nachempfunden war. Heutzutage sind von dem Gelände nur noch wenige Mauern erhalten – dazu Postkarten und wenige schriftliche Berichte.`}
      right={
        "Aus diesem Material hat David Akopyan, einer der Gründer von lemontree.xyz in intensiver Detail-Arbeit eine immersive VRExperience geschaffen, die den User ins Jahr 1919 zurückversetzt. Das komplette Zoo-Gelände ist begehbar; der Spaziergang wird angereichert mit Kommentaren von Zoo-Historiker Michael Sinder.\n\nDas Projekt „Zeitenblicke – Virtual Zoo 1919“ ist ein Showcase für gleich mehrere Potenziale von Virtual Reality. Es ist zugleich innovative Geschichtsvermittlung, Cutting-Edge-Tourismusangebot und Standort-PR für den Auftraggeber, die LBS West, deren Gebäude heute auf Teilen des alten Zoogeländes steht."
      }
    />
  );
}
