import React, { useState } from "react";
import "./Nav.scss";
import { useSpring, animated } from "@react-spring/web";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
export default function Nav({ sub = false }) {
  let [mobileNavOpen, setMobileNavOpen] = useState(false);
  let { height, opacity1, opacity2 } = useSpring({
    height: mobileNavOpen ? 40 : 0,
    opacity1: mobileNavOpen ? 1 : 0,
    opacity2: mobileNavOpen ? 0 : 1,
  });
  if (sub) {
    return (
      <div className="Nav">
        <Link to="/" className="logo">
          AVEMUS
        </Link>
        <div className="flex1"></div>
        <div className="desktopNav">
          <Link to="/" className="navItem">
            Home
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div className="Nav">
      <Link to="/#home" className="logo">
        AVEMUS
      </Link>
      <div className="flex1"></div>
      <div className="desktopNav">
        <a href="#app" className="navItem">
          App
        </a>

        <a href="#vr" className="navItem">
          VR
        </a>
        <a href="#contact" className="navItem">
          Contact
        </a>
      </div>

      <div className="mobileNav">
        <animated.a
          style={{ height }}
          onClick={() => setMobileNavOpen(false)}
          href="/#"
          className="navItem"
        >
          Home
        </animated.a>
        <animated.a
          style={{ height }}
          onClick={() => setMobileNavOpen(false)}
          href="#app"
          className="navItem"
        >
          App
        </animated.a>
        <animated.a
          style={{ height }}
          onClick={() => setMobileNavOpen(false)}
          href="#vr"
          className="navItem"
        >
          VR
        </animated.a>

        <animated.a
          style={{ height }}
          onClick={() => setMobileNavOpen(false)}
          href="#contact"
          className="navItem"
        >
          Contact
        </animated.a>
      </div>
      <div
        onClick={() => setMobileNavOpen(!mobileNavOpen)}
        className="mobileNavButton"
      >
        <animated.div style={{ opacity: opacity2 }} className="buttonIcon">
          <GiHamburgerMenu></GiHamburgerMenu>
        </animated.div>
        <animated.div style={{ opacity: opacity1 }} className="buttonIcon">
          <FaTimes></FaTimes>
        </animated.div>
      </div>
    </div>
  );
}
