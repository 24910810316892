import React, { useEffect, useRef, useState } from "react";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import Advantages from "../../atoms/Advantages/Advantages";
import ContactForm from "../../atoms/ContactForm/ContactForm";
import Footer from "../../atoms/Footer/Footer";
import Layout from "../../atoms/Layout/Layout";
import ProjectBox from "../../atoms/ProjectBox/ProjectBox";
import VRPrices from "../../atoms/VRPrices/VRPrices";
import Workflow from "../../atoms/Workflow/Workflow";
import Apokalypse from "../Apokalypse/Apokalypse";
import Asbeck1878 from "../Asbeck1878/Asbeck1878";
import Iceland360 from "../Iceland360/Iceland360";
import NehabOne from "../NehabOne/NehabOne";
import StandaloneVR from "../StandaloneVR/StandaloneVR";
import TerminalVR from "../TerminalVR/TerminalVR";
import Zoo1919 from "../Zoo1919/Zoo1919";
import "./VR.scss";

export default function VR() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);
  let [muted, setMuted] = useState(true);
  const ref = useRef();
  const overlays = {
    0: <Zoo1919 onClose={() => setOverlay(-1)} />,
    1: <NehabOne onClose={() => setOverlay(-1)} />,
    2: <Asbeck1878 onClose={() => setOverlay(-1)} />,
    3: <Apokalypse onClose={() => setOverlay(-1)} />,
    4: <Iceland360 onClose={() => setOverlay(-1)} />,
    5: <StandaloneVR onClose={() => setOverlay(-1)} />,
    6: <TerminalVR onClose={() => setOverlay(-1)} />,
  };
  let [overlay, setOverlay] = useState(-1);

  return (
    <Layout sub className="VR">
      {overlay !== -1 && <div className="overlay">{overlays[overlay]}</div>}
      <div className="videoContainer">
        <video
          className="videoElem"
          autoPlay
          loop
          muted
          ref={ref}
          src="/renders/vr.mp4"
        ></video>
        <div
          onClick={() => {
            if (muted) {
              ref.current.muted = false;
              ref.current.volume = 1;
              setMuted(false);
            } else {
              ref.current.muted = true;
              ref.current.volume = 0;
              setMuted(true);
            }
          }}
          className="videoMuteButton"
        >
          {muted && <FaVolumeMute></FaVolumeMute>}
          {!muted && <FaVolumeUp></FaVolumeUp>}
        </div>
      </div>
      <div className="partnerLogoGroup">
        <div className="partnerLogos">
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/wh.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/mondi.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/asbeck.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/lwl.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/lbs.png"></img>
          </div>
        </div>
        <div className="partnerLogos">
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/reach.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/innofact.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/beventum.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/sprind.png"></img>
          </div>
          {/*
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/asbeck.png"></img>
          </div>
          */}
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/digihub.png"></img>
          </div>
        </div>
      </div>

      <Advantages></Advantages>
      <div className="section" style={{ paddingBottom: 0 }}>
        <div className="inner" style={{ paddingBottom: 0 }}>
          <div className="title right" style={{ marginBottom: 0 }}>
            Unsere <span className="bold">bisher</span> umgesetzten<br></br>
            <span className="orange">virtuellen Reisen:</span>
          </div>
        </div>
      </div>
      <div className="section" style={{ paddingTop: 0 }}>
        <div className="inner">
          <ProjectBox
            count={"01"}
            title="Zeitenblicke: Zoo 1919"
            text="Ein Spaziergang im Münsteraner Zoo im 19Jhd. 
            am ehemaligen Standtort."
            image={"/thumbs/projekt_zoo_thumbnail.jpg"}
            onClick={() => setOverlay(0)}
            link="/vr/zeitenblicke-zoo-1919"
          ></ProjectBox>
          <ProjectBox
            count={"02"}
            title="Nehab One 2055"
            text="In den Alltag eines Astronauten auf dem Mars 
            schlüpfen im Naturkunde Museum Münster."
            image={"/thumbs/projekt_mars_thumbnail.jpg"}
            onClick={() => setOverlay(1)}
            link="/vr/nehab-one-2055"
          ></ProjectBox>
          <ProjectBox
            count={"03"}
            title="Zeitenblicke: Asbeck 1878"
            text="Auf den Spuren der Therese von Zandt, im ehemaligen 
            Damenstift zu Asbeck."
            image={"/thumbs/projekt_asbeck_thumbnail.jpg"}
            link="/vr/zeitenblicke-asbeck-1878"
            onClick={() => setOverlay(2)}
          ></ProjectBox>
          <ProjectBox
            count={"04"}
            title="Apokalypse Münsterland"
            text="Achtundzwanzig Exponate aus dem Münsterland 
            virtuell in ihrer ursprünglichen 
            Umgebung erleben."
            image={"/thumbs/projekt_apokalypse_thumbnail.jpg"}
            link="/vr/apokalypse-muensterland"
            onClick={() => setOverlay(3)}
          ></ProjectBox>
          <ProjectBox
            count={"05"}
            title="Iceland 360°"
            text="Árni Rúnar unterwegs in der malerischen Natur Islands."
            image={"/thumbs/project_arni_thumbnail.jpg"}
            link="/vr/iceland-360"
            onClick={() => setOverlay(4)}
          ></ProjectBox>
        </div>
      </div>
      <div className="section dark">
        <div className="inner">
          <div className="title">
            Avemus <span className="bold">Workflow</span>
            <br></br>
            in der <span className="orange">Projektumsetzung</span>
          </div>
          <div className="line"></div>
          <Workflow></Workflow>
        </div>
      </div>
      <div className="section" style={{ paddingBottom: 0 }}>
        <div className="inner" style={{ paddingBottom: 0 }}>
          <div className="title right">
            Das perfekte <span className="orange">Abspielgerät</span>
            <br></br>
            für Ihre <span className="bold">Besucher:</span>
          </div>
        </div>
      </div>
      <div className="section" style={{ paddingTop: 0 }}>
        <div className="inner">
          <ProjectBox
            count={"01"}
            title="Standalone VR"
            text="Günstig, Kabellos und Agil."
            image={"/thumbs/standalone_vr_thumb.jpg"}
            link="/vr/standalone-vr"
            onClick={() => setOverlay(5)}
          ></ProjectBox>
          <ProjectBox
            count={"02"}
            title="PC Terminal Virtual Reality"
            text="Außerordentliche Grafik durch Leistungsstarken Rechner."
            image={"/thumbs/terminal_VR_thumb.jpg"}
            link="/vr/pc-terminal-virtual-reality"
            onClick={() => setOverlay(6)}
          ></ProjectBox>
          <div></div>
        </div>
      </div>
      <div className="section grey">
        <div className="inner">
          <div className="title">
            <span className="orange">Preispakete</span> für die Ersellung
            <br></br>
            Ihrer <span className="bold">virtuellen Reise:</span>
          </div>
          <div className="line"></div>
          <VRPrices></VRPrices>
        </div>
      </div>

      <ContactForm></ContactForm>
      <Footer></Footer>
    </Layout>
  );
}
