import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./ProjectBox.scss";

export default function ProjectBox({
  image,
  count,
  title,
  text,
  link = "",
  onClick,
}) {
  return (
    <Link
      to={link}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
      className="ProjectBox"
    >
      <div className="image">
        <img src={image} alt="Project example" />
      </div>
      <div className="count">{count}</div>
      <div className="infos">
        <div className="title">{title}</div>
        <div className="text">{text}</div>
        <div className="flex1"></div>
        <div className="arrow">
          <FaArrowRight></FaArrowRight>
        </div>
      </div>
    </Link>
  );
}
