import React from "react";

export default function Spacer({ w = 0, h = 0 }) {
  return <div style={{ minWidth: w, minHeight: h }}></div>;
}

export function addTwoNumber(a,b){
  return a+b;
}


export function Test2(){
  
}

export function Test3(){
  
}