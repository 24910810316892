import React from "react";
import SubScreen from "../../atoms/SubScreen/SubScreen";
import "./Iceland360.scss";

export default function Iceland360({ onClose }) {
  return (
    <SubScreen
      onClose={onClose}
      title={"Iceland 360"}
      left={"Teammitglied: Árni Rúnar Hrólfsson\nProjektjahr: 2021"}
      images={[
        "/project/project_arni_00.jpg",
        "/project/project_arni_01.jpg",
        "/project/project_arni_02.jpg",
        "/project/project_arni_03.jpg",
      ]}
      mid={
        "Wir haben das Glück, mit dem talentierten Filmemacher und Virtual-Reality-Experten Árni Rúnar Hrólfsson zusammenzuarbeiten. Árni hat umfangreiche Erfahrungen in der Gestaltung von 360-Grad-Projekten für Museen in Norwegen, Island und Deutschland und bringt daher einzigartige Fähigkeiten und Kenntnisse in unsere Zusammenarbeit ein.\n\nÁrni arbeitet eng mit unserem Team zusammen und trägt als Filmemacher wesentlich zur Entwicklung innovativer VR-Projekte bei. Seine Leidenschaft und sein Engagement für die Arbeit machen ihn zu einem wertvollen Mitglied unseres Teams und helfen uns, die Erwartungen unserer Kunden zu übertreffen."
      }
      right={
        "Die Zusammenarbeit mit Arni hat es uns ermöglicht, unser Angebot an VR-Lösungen zu erweitern und neue Wege der Nutzung von Virtual Reality in verschiedenen Bereichen wie Museen, Bildung und Training zu erforschen. Durch seine umfangreiche Erfahrung und sein tiefes Verständnis der Technologie sind wir in der Lage, maßgeschneiderte Lösungen für unsere Kunden zu entwickeln und einzigartige Erlebnisse zu schaffen.\n\nWir schätzen Arnis Beitrag zu unserem Unternehmen und sind stolz darauf, mit ihm zusammenzuarbeiten, um die Grenzen von Virtual Reality weiter auszudehnen. "
      }
    />
  );
}
