import "./App.scss";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/Home/Home";
import VR from "./screens/VR/VR";
import Loqui from "./screens/Loqui/Loqui";
import Zoo1919 from "./screens/Zoo1919/Zoo1919";
import NehabOne from "./screens/NehabOne/NehabOne";
import Asbeck1878 from "./screens/Asbeck1878/Asbeck1878";
import Apokalypse from "./screens/Apokalypse/Apokalypse";
import StandaloneVR from "./screens/StandaloneVR/StandaloneVR";
import TerminalVR from "./screens/TerminalVR/TerminalVR";
import Iceland360 from "./screens/Iceland360/Iceland360";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/vr" element={<VR />} />
        <Route path="/loqui" element={<Loqui />} />
        <Route path="/vr/zeitenblicke-zoo-1919" element={<Zoo1919 />} />
        <Route path="/vr/nehab-one-2055" element={<NehabOne />} />
        <Route path="/vr/zeitenblicke-asbeck-1878" element={<Asbeck1878 />} />
        <Route path="/vr/apokalypse-muensterland" element={<Apokalypse />} />
        <Route path="/vr/standalone-vr" element={<StandaloneVR />} />
        <Route path="/vr/iceland-360" element={<Iceland360 />} />
        <Route
          path="/vr/pc-terminal-virtual-reality"
          element={<TerminalVR />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
