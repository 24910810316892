import React from "react";
import SubScreen from "../../atoms/SubScreen/SubScreen";
import "./NehabOne.scss";

export default function NehabOne({ onClose }) {
  return (
    <SubScreen
      onClose={onClose}
      title={"Mars\nNehab One"}
      left={"Kunde: LWL Museum für Naturkunde,\nProjektjahr: 2020"}
      images={[
        "/project/projekt_mars_00.jpg",
        "/project/projekt_mars_01.jpg",
        "/project/projekt_mars_02.jpg",
        "/project/projekt_mars_03.jpg",
      ]}
      mid={
        "Im Rahmen der Ausstellung „Überlebenskünstler Mensch“ des LWL Museums für Naturkunde, wird mit „NEHAB ONE“ eine virtuelle Reise zu einer zukünftigen Marsstation simuliert.\n\nZunächst gibt es eine kurze Einführung, in der die Grundlagen der Interaktion mit der VR erklärt werden, worauf hin die Mission auf der Marsstation beginnt."
      }
      right={
        "Aufgabe für die Besucher ist es einen Kontrollgang durch die Station durchzuführen, bevor der Sauerstoffvorrat aufgebraucht ist.\n\nIn der Abenddämmerung auf dem Mars werden Bestandteile der Station durch animierte Szenarien und interaktive Aufgaben erläutert."
      }
      credit="© LWL/Perdok 2020 - Konzeption und 3D-Modellierung der Station Robert Perdok"
    />
  );
}
