import React from "react";
import SubScreen from "../../atoms/SubScreen/SubScreen";
import "./TerminalVR.scss";

export default function TerminalVR({ onClose }) {
  return (
    <SubScreen
      onClose={onClose}
      title={"PC Virtual Reality"}
      images={["/thumbs/terminal_VR.jpg"]}
      left={
        "+ interaktiv\n+ detaillierte Grafik\n+ weitläufige Welten\n+ Bildschirm inkl. \n+ Fest verbaut"
      }
      mid={
        "PC VR-Headsets bieten dank leistungsstarker Computer-Hardware eine High-Fidelity-Grafik, die Standalone VR-Headsets nicht erreichen können. PC VR-Headsets können auch in einem schönen Terminal Verbaut werden, was ein erweitertes Erlebnis für Besucher schafft, da auch mehr als ein Besucher gleichzeitig auf einem Bildschirm an der Virtuellen Reise teilnehmen kann.\n\nPC VR ermöglicht auch weitläufige Welten mit vielen Details, die Besucher in einer VR-Erfahrung erkunden können. Die erweiterte Interaktivität von PC VR ermöglicht es Museen, immersivere Erfahrungen zu schaffen, die Besucher dazu ermutigt, in die virtuelle Welt einzutauchen und Präsentierte Inhalte auf eigene Faust zu erkunden."
      }
      right={
        "Insgesamt bieten Standalone und PC VR-Headsets Museen eine hervorragende Möglichkeit, Besuchern immersive VR-Erfahrungen zu bieten. Standalone VR-Headsets sind klein, aber oho, interaktiv, günstig, kabellos und agil. PC VR-Headsets bieten eine High-Fidelity-Grafik, schöne Terminals, weitläufige Welten mit vielen Details und hohe Interaktivität.\n\nDie Wahl zwischen Standalone und PC VR hängt davon ab, welche Art von Erfahrung das Museum den Besuchern bieten möchte und welche Ressourcen zur Verfügung stehen. In jedem Fall kann VR-Technologie Museen helfen, ihre Ausstellungen zu erweitern und den Besuchern ein einzigartiges und faszinierendes Erlebnis zu bieten."
      }
    />
  );
}
