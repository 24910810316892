import React, { useState } from "react";
import "./Footer.scss";

export default function Footer() {
  return (
    <div className="Footer">
      <div className="addressWrapper">
        <div className="address">
          Besuchen Sie uns vor Ort:<br></br>
          <br></br>
          <b>
            Lemonverse<br></br>
            Markweg 30<br></br>48147 Münster
          </b>
            <br></br>
            <br></br>
            <br></br>
          Oder rufen Sie uns an
          <br></br>
          <br></br>
          <b>+49 (0) 174 186 1175</b>
        </div>

        <MapBox />
      </div>
      AVEMUS™ ist ein Teil des Zitroversums 🍋
      <div className="footerLinks">
        <a href="/#">Impressum</a> | <a href="/#">Datenschutzerklärung</a>
      </div>
    </div>
  );
}

function MapBox() {
  let [accept, setAccept] = useState(false);
  return (
    <>
      {accept ? (
        <div className="frame">
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9828.275251810863!2d7.6186962!3d51.9873994!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x28b83433c2c5d32f!2slemontree.xyz%20GbR!5e0!3m2!1sde!2sde!4v1658828477982!5m2!1sde!2sde"
            className="googleMap"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      ) : (
        <img
          alt="mapPreview"
          style={{ maxWidth: "450px", width: "100%" }}
          onClick={() => {
            setAccept(true);
          }}
          src="/map.png"
        ></img>
      )}
    </>
  );
}
