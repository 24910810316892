import React from "react";
import { FaArrowLeft, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import "./SubScreen.scss";

export default function SubScreen({
  title = "Zoo Zeitreise 1919",
  images = ["/renders/VRBrille_Output.jpg", "/renders/VRBrille_Output.jpg"],
  left = "Kunde: Asbeck",
  mid = "Projekt: VR-Brille",
  right = "Zeitraum: 2019",
  credit,
  onClose,
}) {
  const nav = useNavigate();
  const [selectedImage, setSelectedImage] = React.useState(0);
  return (
    <Layout sub className="SubScreen">
      <div
        className="backButton"
        onClick={() => {
          onClose ? onClose() : nav(-1);
        }}
      >
        <FaArrowLeft></FaArrowLeft>
      </div>
      <div className="imageContainer">
        {selectedImage > 0 && (
          <div
            className="imageButton prev"
            onClick={() => {
              setSelectedImage(selectedImage - 1);
            }}
          >
            <FaChevronLeft></FaChevronLeft>
          </div>
        )}
        <img src={images[selectedImage % images.length]} alt="Project" />
        {selectedImage < images.length - 1 && (
          <div
            className="imageButton next"
            onClick={() => {
              setSelectedImage(selectedImage + 1);
            }}
          >
            <FaChevronRight></FaChevronRight>
          </div>
        )}
      </div>
      <div className="title">{title}</div>

      <div className="line"></div>
      <div className="trippleArea">
        <div className="left">{left}</div>
        <div className="mid">{mid}</div>
        <div className="right">{right}</div>
      </div>
      <div className="trippleArea">
        <div className="credit">{credit}</div>
      </div>
    </Layout>
  );
}
