import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import "./Block.scss";

export default function Block({
  color = "white",
  image,
  rightText,
  mobileImage,
  anchor,
  header = "GIVE ME A HEADER",
  sub = "The newest experience",
  grey,
  linkText = "Mehr erfahren",
  linkTarget = "/vr",
  linkToAvemus,
}) {
  let cn = classNames("Block", { [`color-${color}`]: true, rightText });
  let mobileCn = classNames("Block mobile", { [`color-${color}`]: true });
  let BoxContent = () => (
    <>
      <div className="inner">
        <div className="spacerOne"></div>
        <div className="textContainer">
          <div className="headline">{header}</div>
          <div className="subline">{sub}</div>
          <div className="greyline">{grey}</div>
          {linkToAvemus ? (
            <a className="moreLink" href="https://avemus.app">
              {linkText}
            </a>
          ) : (
            <Link className="moreLink" to={linkTarget || "/#"}>
              {linkText}
            </Link>
          )}
        </div>
        <div className="spacerTwo"></div>
      </div>
    </>
  );
  return (
    <>
      <a href="/#" className="anchor" id={anchor} name={anchor}>
        &nbsp;
      </a>
      {linkToAvemus ? (
        <a href="https://avemus.app">
          <div className={cn} style={{ backgroundImage: "url(" + image + ")" }}>
            <BoxContent></BoxContent>
          </div>
        </a>
      ) : (
        <Link to={linkTarget || "/#"}>
          <div className={cn} style={{ backgroundImage: "url(" + image + ")" }}>
            <BoxContent></BoxContent>
          </div>
        </Link>
      )}
        {linkToAvemus ? (
        <a href="https://avemus.app">
          <div className={mobileCn} style={{ backgroundImage: "url(" + mobileImage + ")" }}>
            <BoxContent></BoxContent>
          </div>
        </a>
      ) : (
        <Link to={linkTarget || "/#"}>
          <div className={mobileCn} style={{ backgroundImage: "url(" + mobileImage + ")" }}>
            <BoxContent></BoxContent>
          </div>
        </Link>
      )}

    </>
  );
}
