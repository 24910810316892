import React, { useCallback, useState } from "react";
import Loading from "react-loading";
import "./ContactForm.scss";

export default function ContactForm() {
  let [name, setName] = useState("");
  let [mail, setMail] = useState("");
  let [message, setMessage] = useState("");
  let [status, setStatus] = useState("start");

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      console.log("send form");
      let formData = new FormData();
      formData.append("name", name);
      formData.append("mail", mail);
      formData.append("message", message);
      formData.append("form-name", "Contact");
      setStatus("loading");
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          console.log("Form successfully submitted");
          setStatus("done");
        })
        .catch((error) => {
          setStatus("error");
          console.log(error);
        });
    },
    [name, mail, message]
  );
  return (
    <>
      <div className="ContactForm">
        <a id="contact" name="contact" className="hiddenAnchor" href="/#">
          &nbsp;
        </a>
        <div className="contactTitle">Contact</div>
        <div className="contactContent">
          <div className="flex" style={{ gap: 10 }}>
            <div className="flex flex1">
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Ihr Name"
                className="contactInput"
              />
            </div>
            <div className="flex flex1">
              <input
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                type="text"
                placeholder="Ihre E-Mail"
                className="contactInput"
              />
            </div>
          </div>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{ minHeight: 120, marginTop: 10 }}
            type="text"
            placeholder="Ihre Nachricht"
            className="contactInput"
          />
          <button
            className={"contactInput contactButton " + status}
            onClick={handleSubmit}
          >
            {status === "done" && "Vielen Dank für Ihre Nachricht"}
            {status === "start" && "Nachricht abschicken"}
            {status === "loading" && (
              <Loading height={20} width={20} type="spin"></Loading>
            )}
          </button>
        </div>
      </div>
      <div className="emailText">
        oder schreiben Sie uns eine Mail
        <a href="mailto:hello@avemus.de" className="emailLink">
          hello@avemus.de
        </a>
      </div>
    </>
  );
}
