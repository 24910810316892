import React from "react";
import Aufklapper from "../../atoms/Aufklapper/Aufklapper";
import Layout from "../../atoms/Layout/Layout";
import "./Loqui.scss";

export default function Loqui() {
  return (
    <Layout className="Loqui" sub>
      <Aufklapper
        header="Neue Erlebnisse"
        sub="Erweitern Sie Ihr Angebot und bieten Sie den Besuchern ein interaktives Erlebnis, das sie nicht vergessen werden."
        image="/renders/TestBox.jpg"
        grey={""}
        linkTarget={false}
        mobileImage="/renders/VRBrille_Mobile_Output.jpg"
        moreImages={[
       
        ]}
      ></Aufklapper>
    </Layout>
  );
}
