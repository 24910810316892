import React from "react";
import SubScreen from "../../atoms/SubScreen/SubScreen";
import "./StandaloneVR.scss";

export default function StandaloneVR({ onClose }) {
  return (
    <SubScreen
      onClose={onClose}
      title={"Standalone Virtual Reality"}
      images={["/thumbs/standalone_vr.jpg"]}
      left={"+ günstig\n+ handlich\n+ kabellos\n+ interaktiv"}
      mid={
        "Standalone VR-Headsets sind eigenständige Geräte, die alles haben, was sie brauchen, um VR-Inhalte darzustellen. Diese Geräte sind klein, aber leistungsstark und bieten eine hervorragende Möglichkeit, Besuchern eine immersive VR-Erfahrung zu bieten.\n\nStandalone VR-Headsets sind auch günstiger als PC VR-Headsets, da sie keine teure Computer-Hardware benötigen. Außerdem sind sie kabellos, was bedeutet, dass Besucher sich frei bewegen und die VR-Erfahrung in voller Bewegungsfreiheit genießen können.\n\nDie Agilität der Standalone VR-Headsets ermöglicht es Museen, eine Vielzahl von VR-Erfahrungen zu schaffen, die sowohl unterhaltsam als auch informativ sind. Für das Abspielen von 360° Inhalten sind Standalone Lösungen absolut ausreichend."
      }
      right={
        "Insgesamt bieten Standalone und PC VR-Headsets Museen eine hervorragende Möglichkeit, Besuchern immersive VR-Erfahrungen zu bieten. Standalone VR-Headsets sind klein, aber oho, interaktiv, günstig, kabellos und agil\n\nPC VR-Headsets bieten eine High-Fidelity-Grafik, schöne Terminals, weitläufige Welten mit vielen Details und hohe Interaktivität.\n\nDie Wahl zwischen Standalone und PC VR hängt davon ab, welche Art von Erfahrung das Museum den Besuchern bieten möchte und welche Ressourcen zur Verfügung stehen. In jedem Fall kann VR-Technologie Museen helfen, ihre Ausstellungen zu erweitern und den Besuchern ein einzigartiges und faszinierendes Erlebnis zu bieten."
      }
    />
  );
}
