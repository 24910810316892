import React from "react";
import Nav from "../Nav/Nav";
import "./Layout.scss";

export default function Layout({ children, sub, className }) {
  return (
    <div className={"Layout " + className}>
      <Nav sub={sub}></Nav>
      {children}
    </div>
  );
}
