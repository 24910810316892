import React from "react";
import SubScreen from "../../atoms/SubScreen/SubScreen";
import "./Apokalypse.scss";

export default function Apokalypse({onClose}) {
  return (
    <SubScreen
    onClose={onClose}
      title={"Apokalypse Münsterland"}
      left={"Kunde: Münsterland e.V.\nProjektjahr: 2019"}
      images={[
        "/project/projekt_apo_00.jpg",
        "/project/projekt_apo_01.jpg",
        "/project/projekt_apo_02.jpg",
        "/project/projekt_apo_03.jpg",
        "/project/projekt_apo_04.jpg",
        "/project/projekt_apo_05.jpg",
        "/project/projekt_apo_06.jpg",
      ]}
      mid={
        "„Apokalypse Münsterland“ ist ein Kooperationsprojekt von Museen und Ausstellungshäusern, die digitale Vermittlungsmethoden und besucherorientierte Ansätze ausprobieren, um sich zukunftsfähig aufzustellen.\n\nUnter der Projektleitung des Münsterland e.V. arbeiteten 28 kulturelle Einrichtungen im Münsterland mit den Kooperationspartnern FH Münster, Fachbereich Architektur (Münster School of Architecture, MSA), BOK + Gärtner GmbH (Agentur für Kommunikationsdesign und Szenografie), dem FabLab des münsterLAND.digital e.V. und den Gründern von lemontree.xyz zusammen."
      }
      right={
        "Als konkretes Ergebnis entstand eine digitale und mobilisierte Ausstellung. Sie tourte im Sommer 2019 durch 16 Stationen im Münsterland und verhalf den beteiligten Einrichtungen neue Digitalisierungskonzepte, wie z.B. Integration von Virtual Reality umzusetzen. Darüber hinaus zielte sie auf die Ansprache von neuen Besuchergruppen ab.\n\n„Schickt uns Kultur! Kunst, Artefakte, Literatur, Objekte, Ideen, Konzepte, Schätze!“ heißt es in dem SOS aus der Zukunft. Die Zukunft will erfahren, was den Menschen wichtig ist. Was sie bewegt. Was sie bewahren wollen."
      }
      credit={"© Fotos von BoK und Gärtner Christian Steinweg"}
    />
  );
}
