import React, { useState } from "react";
import "./Aufklapper.scss";

import classNames from "classnames";
import { Link } from "react-router-dom";

export default function Aufklapper({
  color = "white",
  image,
  rightText,
  mobileImage,
  anchor,
  header = "MacBook Air",
  sub = "The newest experience",
  grey = "Available next month",
  linkText = "Mehr erfahren",
  linkTarget = "/vr",
  moreImages,
}) {
  let [open, setOpen] = useState(false);
  let BoxContent = () => (
    <>
      <div className="inner" onClick={() => setOpen(!open)}>
        <div className="spacerOne"></div>
        <div className="textContainer">
          <div className="headline">{header}</div>
          <div className="subline">{sub}</div>
          <div className="greyline">{grey}</div>
          {linkTarget && (
            <Link className="moreLink" to={linkTarget || "/#"}>
              {linkText}
            </Link>
          )}
        </div>
        <div className="spacerTwo"></div>
      </div>
    </>
  );
  let cn = classNames("Aufklapper", {
    open,
    [`color-${color}`]: true,
    rightText,
  });
  let mobileCn = classNames("Aufklapper mobile", {
    open,
    [`color-${color}`]: true,
  });

  return (
    <>
      <a href="/#" className="anchor" id={anchor} name={anchor}>
        &nbsp;
      </a>
      <div className={cn} style={{ backgroundImage: "url(" + image + ")" }}>
        <BoxContent></BoxContent>
      </div>
      <div
        className={mobileCn}
        style={{ backgroundImage: "url(" + mobileImage + ")" }}
      >
        <BoxContent></BoxContent>
      </div>
      <div className={"moreImages " + (open ? "open " : "")}>
        {moreImages.map((i) => {
          return (
            <div className="imgWrap">
              <img alt="background" src={i}></img>
            </div>
          );
        })}
      </div>
    </>
  );
}
