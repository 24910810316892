import React from "react";
import SubScreen from "../../atoms/SubScreen/SubScreen";
import "./Asbeck1878.scss";

export default function Asbeck1878({ onClose }) {
  return (
    <SubScreen
      onClose={onClose}
      title={"Zeitblicke\nAsbeck 1782"}
      left={"Kunde: Heimatverein Asbeck\nProjektjahr: 2022"}
      images={[
        "/project/projekt_asbeck_00.jpg",
        "/project/projekt_asbeck_01.jpg",
        "/project/projekt_asbeck_02.jpg",
        "/project/projekt_asbeck_03.jpg",
        "/project/projekt_asbeck_04.jpg",
        "/project/projekt_asbeck_05.jpg",
        "/project/projekt_asbeck_06.jpg",
      ]}
      mid={
        "Das Projekt Asbeck-VR war allein aufgrund seines Umfanges, eine besondere Herausforderung und Chance. Das ehemalige Damenstift im Jahr 1782 zu rekonstruieren und Nutzern die Möglichkeit zu geben, in die Rolle der Therese von Zandt zu schlüpfen, war eine unserer spannendsten Aufgaben.\n\nWir hatten großen Spaß daran, in Zusammenarbeit mit dem Heimatverein Asbeck, das Damenstift in seiner historischen Umgebung zu erforschen und zu rekonstruieren. Die Vereinsmitglieder welche selbst Historiker Architekten und Geschichtsenthusiasten sind gaben uns wertvolle Informationen und Einblicke in die Geschichte des Stifts.\n\nDas Ergebnis ist eine beeindruckende virtuelle Rekonstruktion, die Nutzern die Möglichkeit gibt, das Damenstift in seiner historischen Umgebung zu erkunden und zu erfahren, wie das Leben in dieser Zeit aussah."
      }
      right={
        "Durch die Verwendung von Virtual Reality-Technologie und unsere sorgfältige Recherche und Rekonstruktion, bietet Asbeck-VR eine realistische und immersive Erfahrung, die es ermöglicht, die Vergangenheit auf eine neue Art und Weise zu entdecken.\n\nEs hat uns sehr viel Freude und lehrreiche Erfahrungen bereitet, das Projekt Asbeck-VR zu entwickeln und wir sind stolz darauf, das der Heimatverein Asbeck dank unserer zusammenarbeit nun Zeitreisen im Stiftsmuseum anbeiten kann. Wir hoffen, dass es viele Menschen dazu inspirieren wird, sich mit der Geschichte des Damenstifts zu Asbeck auseinandersetzen und sich in die Vergangenheit zu versetzen."
      }
    />
  );
}
